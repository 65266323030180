small
<template>
  <validation-provider
    #default="{ errors }"
    :name="defaultName"
    mode="passive"
    :rules="rules"
  >
    <b-form-group :description="description" :label="label" :label-for="id">
      <quill-editor
        :state="$attrs.state || errors.length > 0 ? false : null"
        :name="name"
        :id="id"
        v-bind="$attrs"
        v-on="$listeners"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </b-form-group>
  </validation-provider>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import { BFormGroup } from "bootstrap-vue";
// eslint-disable-next-line
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";

import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
} from "@validations";
export default {
  components: {
    ValidationProvider,
    quillEditor,
    BFormGroup,
  },
  props: {
    id: {
      type: String,
      default: () => "input",
    },
    name: {
      type: String,
    },
    rules: {
      type: String,
      default: () => "",
    },
    label: {
      type: String,
      default: () => "",
    },
    description: {
      type: String,
      default: () => "",
    },
  },
  data() {
    return {
      required,
      email,
      confirmed,
      url,
      between,
      alpha,
      integer,
      password,
      min,
      digits,
      alphaDash,
      length,
    };
  },
  computed: {
    defaultName() {
      return this.name || this.label;
    },
  },
};
</script>
