<template>
  <validation-provider
    #default="{ errors }"
    :name="name"
    :vid="id"
    :rules="rules"
    mode="passive"
  >
    <b-form-group class="ngo-datepicker" :label-for="id" :label="label">
      <b-form-timepicker
        :id="id"
        :locale="isRTL ? 'ar' : 'en'"
        :dir="isRTL"
        v-bind="$attrs"
        v-on="$listeners"
      />
    </b-form-group>
    <small class="text-danger">{{ errors[0] }}</small>
  </validation-provider>
</template>

<script>
import { BFormGroup, BFormTimepicker } from "bootstrap-vue";
import { $themeConfig } from "@themeConfig";
import { ValidationProvider } from "vee-validate";
export default {
  components: { BFormGroup, ValidationProvider, BFormTimepicker },
  props: {
    id: {
      type: String,
      default: () => "datepicker",
    },
    name: {
      type: String,
      default: () => "datepicker",
    },
    rules: {
      type: String,
      default: () => "",
    },
    label: {
      type: String,
      default: () => "",
    },
  },
  data() {
    return {
   
    };
  },
  computed: {
    isRTL() {
      return $themeConfig.layout.isRTL ? "rtl" : "ltr";
    },
  },
};
</script>

<style lang="scss">
[dir="rtl"] .b-form-timepicker .dropdown-menu {
  right: 0%;
}
.ngo-datepicker {

  footer {
    display: none;
  }
  .dropdown-menu {
    width: fit-content !important;
  }
  .b-form-date-calendar {
    width: fit-content !important;
  }
}
</style>
