<template>
  <validation-provider
    #default="{ errors }"
    :name="defaultName"
    mode="passive"
    :rules="rules"
    class="form_mask"
  >
    <b-form-group :description="description" :label="label" :label-for="id">
      <b-input-group dir="rtl" class="input-group-merge">
        <cleave
          dir="rtl"
          v-bind="$attrs"
          v-on="$listeners"
          :name="name"
          class="form-control form-control-merge px-1"
          :raw="false"
          :id="id"
          :type="type == 'password' ? passwordFieldType : ''"
          :options="optionMask"
          :class="{ 'cleave-error': errors.length > 0 }"
          :placeholder="placeholder"
        />
        <b-input-group-prepend
          v-if="isPhone"
          is-text
          :class="{ 'cleave-pone': errors.length > 0 }"
          style="borde"
        >
          (+963)
        </b-input-group-prepend>
        <b-input-group-prepend
          v-if="isPassword"
          is-text
          :class="{ 'cleave-pone': errors.length > 0 }"
        >
          <feather-icon
            class="cursor-pointer"
            :icon="passwordToggleIcon"
            @click="togglePasswordVisibility"
          />
        </b-input-group-prepend>
      </b-input-group>
    </b-form-group>
    <small class="text-danger">{{ errors[0] }}</small>
  </validation-provider>
</template>

<script>
import Cleave from "vue-cleave-component";
// eslint-disable-next-line import/no-extraneous-dependencies
import "cleave.js/dist/addons/cleave-phone.us";
import { ValidationProvider } from "vee-validate";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";

import { BFormGroup, BFormInput, BInputGroup, BInputGroupPrepend } from "bootstrap-vue";
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
} from "@validations";
export default {
  props: {
    raw: {
      type: Boolean,
    },
    id: {
      type: String,
      default: () => "input",
    },
    name: {
      type: String,
      default: () => "",
    },
    rules: {
      type: String,
      default: () => "",
    },
    label: {
      type: String,
      default: () => "",
    },
    description: {
      type: String,
      default: () => "",
    },
    placeholder: {
      type: String,
      default: () => "",
    },
    options: {
      type: String,
      default: () => "",
    },
    isPhone: {
      type: Boolean,
      default: () => false,
    },
    isPassword: {
      type: Boolean,
      default: () => false,
    },
    type: {
      type: String,
      default: () => "",
    },
    dir: {
      type: String,
      default: () => "rtl",
    },
  },
  components: {
    ValidationProvider,
    BFormGroup,
    BFormInput,
    Cleave,
    BInputGroup,
    BInputGroupPrepend,
    togglePasswordVisibility,
  },
  mixins: [togglePasswordVisibility],

  data() {
    return {
      required,
      email,
      confirmed,
      url,
      between,
      alpha,
      integer,
      password,
      min,
      digits,
      alphaDash,
      length,
      optionMask: {},
      option: {
        password: {
          // creditCard: true,
          blocks: [10],
        },
        creditCard: {
          creditCard: true,
        },
        date: {
          date: true,
          delimiter: "-",
          datePattern: ["Y", "m", "d"],
        },
        phone: {
          blocks: [10],
          numericOnly: true,
        },
        time: {
          time: true,
          timePattern: ["h", "m"],
        },
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
        },
        block: {
          blocks: [4, 3, 3],
          uppercase: true,
        },
        delimiter: {
          delimiter: "·",
          blocks: [3, 3, 3],
          uppercase: true,
        },
        customDelimiter: {
          delimiters: [".", ".", "-"],
          blocks: [3, 3, 3, 2],
          uppercase: true,
        },
        prefix: {
          prefix: "+63",
          blocks: [3, 3, 3, 4],
          uppercase: true,
        },
      },
    };
  },
  methods: {},
  computed: {
    defaultName() {
      return this.name || this.label;
    },
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  created() {
    Object.keys(this.option).forEach((key) => {
      if (key == this.options) {
        this.optionMask = JSON.parse(JSON.stringify(this.option[key]));
      }
    });
  },
};
</script>

<style lang="scss">
.form_mask {
  .input-group-prepend .input-group-text {
    border-right: 1px solid #d8d6de;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}
</style>
