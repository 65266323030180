small
<template>
  <validation-provider
    #default="{ errors }"
    :name="defaultName"
    :vid="id"
    mode="passive"
    :rules="rules"
  >
    <b-form-group :label="label" :label-for="id">
      <b-form-textarea
        :state="errors.length > 0 ? false : null"
        :name="name"
        :id="id"
        v-bind="$attrs"
        v-on="$listeners"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </b-form-group>
  </validation-provider>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import { BFormGroup, BFormTextarea } from "bootstrap-vue";

export default {
  components: {
    ValidationProvider,
    BFormTextarea,
    BFormGroup,
  },
  props: {
    id: {
      type: String,
      default: () => "input",
    },
    name: {
      type: String,
    },
    rules: {
      type: String,
      default: () => "",
    },
    label: {
      type: String,
      default: () => "",
    },
  },
  computed: {
    defaultName() {
      return this.name || this.label;
    },
  },
};
</script>
