<template>
  <div>
    <b-modal
      v-if="forceModal || (Object.keys(formSchema).length < 8 && !forceSide)"
      v-model="modalOpen"
      footer-class="removed"
      centered
      no-close-on-backdrop
      v-bind="$attrs"
      v-on="$listeners"
      class="form_madal"
    >
      <validation-observer ref="FormValidation">
        <b-form @submit.prevent="onSubmit">
          <b-row>
            <b-col v-for="(field, key) in formSchema" :key="key" cols="12">
              <slot :name="field.key" v-bind:form="form" />
              <template v-if="field.component">
                <component
                  v-if="field.numberModel"
                  :is="field.component"
                  v-bind="field.attrs"
                  v-on="field.listeners"
                  v-model.number="form[field.key]"
                ></component>
                <component
                  v-else
                  :is="field.component"
                  v-bind="field.attrs"
                  v-on="field.listeners"
                  v-model="form[field.key]"
                ></component>
              </template>
            </b-col>
          </b-row>
          <hr />
          <b-row class="justify-content-end px-1">
            <b-button class="mx-1" @click="modalOpen = false" v-if="!BCansel">
              <feather-icon icon="XIcon" class="mr-50" />
              إلغاء
            </b-button>
            <loader-btn
              :label="confirmLabel"
              :icon="confirmIcon"
              variant="primary"
              :loading="loading"
              type="submit"
            >
            </loader-btn>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
    <b-sidebar
      v-else
      v-model="modalOpen"
      width="450px"
      id="edit-sidebar"
      shadow
      bg-variant="white"
      backdrop
    >
      <!-- <h2 class="px-2">{{title }}</h2> -->
      <hr />
      <validation-observer ref="FormValidation">
        <b-form class="px-2 py-50" @submit.prevent="onSubmit">
          <b-row>
            <b-col v-for="(field, key) in formSchema" :key="key" cols="12">
              <slot :name="key" v-bind:form="form" />
              <template v-if="field.component">
                <component
                  v-if="field.numberModel"
                  :is="field.component"
                  v-bind="field.attrs"
                  v-on="field.listeners"
                  v-model.number="form[field.key]"
                ></component>
                <component
                  v-else
                  :is="field.component"
                  v-bind="field.attrs"
                  v-on="field.listeners"
                  v-model="form[field.key]"
                ></component>
              </template>
            </b-col>
          </b-row>
          <hr />
          <b-row class="justify-content-end mt-auto px-1">
            <b-button class="mx-1" @click="modalOpen = false">
              <feather-icon icon="XIcon" class="mr-25" />
              إلغاء
            </b-button>
            <loader-btn
              :label="confirmLabel"
              :icon="confirmIcon"
              variant="primary"
              :loading="loading"
              type="submit"
            >
            </loader-btn>
          </b-row>
        </b-form>
      </validation-observer>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BModal,
  BRow,
  BCol,
  BForm,
  BButton,
  BSidebar,
  BFormCheckbox,
} from "bootstrap-vue";
import { ValidationObserver } from "vee-validate";
import FormInput from "@/components/form-input";
import VSelect from "vue-select";
import LoaderBtn from "@/components/loader-btn.vue";
import FormTextarea from "@/components/form-textarea";
import FormDatepicker from "@/components/form-datepicker";
import FormSelect from "@/components/form-select/index.vue";
import FormMask from "@/components/form-mask/index.vue";
import FormQuill from "@/components/form-quill/index.vue";
import GeneratePassword from "@/components/generate-password/index.vue";
import FormTimepicker from "@/components/form-time/index.vue"
export default {
  props: {
    title:String,
    formSchema: Object,
    value: Boolean,
    btnLabel: String,
    loading: Boolean,
    forceSide: {
      type: Boolean,
      default: () => false,
    },
    forceModal: {
      type: Boolean,
      default: () => false,
    },
    BCansel: {
      type: Boolean,
      default: () => false,
    },
    confirmLabel: String,
    confirmIcon: String,
  },

  components: {
    BModal,
    BRow,
    BCol,
    BForm,
    BButton,
    BSidebar,
    BFormCheckbox,
    ValidationObserver,
    FormInput,
    LoaderBtn,
    VSelect,
    FormTextarea,
    FormDatepicker,
    FormSelect,
    FormMask,
    FormQuill,GeneratePassword,
    FormTimepicker
  },
  data() {
    return {
      form: {},
    };
  },
  computed: {
    modalOpen: {
      set(val) {
        this.$emit("input", val);
      },
      get() {
        return this.value;
      },
    },
  },
  watch: {
    modalOpen(val) {
      if (!val) {
        requestAnimationFrame(() => {
          this.form = {};
          this.$refs.FormValidation.reset();
        });
      }
    },
  },
  methods: {
    init(form) {
      this.form = JSON.parse(JSON.stringify(form));
    },
    async onSubmit() {
      await this.$refs.FormValidation.validate().then(async (success) => {
        if (success) {
          this.$emit("confirm", this.form);
        }
      });
    },
  },
};
</script>
<style lang="scss">
.removed {
  display: none;
}
</style>