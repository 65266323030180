<template>
  <b-button v-bind="$attrs" v-on="$listeners" :disabled="loading" :type="type">
    <div v-if="loading" class="row align-items-center">
      <b-spinner class="mx-50" small />
      {{ loadingLabel }}
    </div>

    <div v-else>
      <feather-icon v-if="!noIcon" :icon="icon" class="mr-50" />
      <span>{{ label }}</span>
    </div>
  </b-button>
</template>

<script>
import { BButton, BSpinner } from "bootstrap-vue";
export default {
  components: {
    BButton,
    BSpinner,
  },
  props: {
    loading: {
      type: Boolean,
      default: () => false,
    },
    noIcon: {
      type: Boolean,
      default: () => false,
    },
    loadingLabel: {
      type: String,
      default: () => "جاري الحفظ",
    },
    label: {
      type: String,
      default: () => "حفظ",
    },
    type: {
      type: String,
    },
    icon: {
      type: String,
      default: () => "SaveIcon",
    },
  },
};
</script>
