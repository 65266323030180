<template>
  <validation-provider
    #default="{ errors }"
    :name="name"
    :vid="id"
    :rules="rules"
    mode="passive"
  >
    <b-form-group class="ngo-datepicker" :label-for="id" :label="label">
      <b-form-datepicker
        :id="id"
        :locale="isRTL ? 'ar-sy' : 'en-US'"
        :dir="isRTL"
        hide-header
        v-bind="{ ...$attrs, ...ar }"
        v-on="$listeners"
      />
    </b-form-group>
    <small class="text-danger">{{ errors[0] }}</small>
  </validation-provider>
</template>

<script>
import { BFormGroup, BFormDatepicker } from "bootstrap-vue";
import { $themeConfig } from "@themeConfig";
import { ValidationProvider } from "vee-validate";
export default {
  components: { BFormGroup, BFormDatepicker, ValidationProvider },
  props: {
    id: {
      type: String,
      default: () => "datepicker",
    },
    name: {
      type: String,
      default: () => "datepicker",
    },
    rules: {
      type: String,
      default: () => "",
    },
    label: {
      type: String,
      default: () => "",
    },
  },
  data() {
    return {
      ar: {
        labelPrevDecade: "العقد السابق",
        labelPrevYear: "العام السابق",
        labelPrevMonth: "الشهر السابق",
        labelCurrentMonth: "الشهر الحالي",
        labelNextMonth: "الشهر المقبل",
        labelNextYear: "العام المقبل",
        labelNextDecade: "العقد القادم",
        labelToday: "اليوم",
        labelSelected: "التاريخ المحدد",
        labelNoDateSelected: "لم يتم اختيار تاريخ",
        labelCalendar: "التقويم",
        labelNav: "الملاحة التقويم",
        labelHelp: "استخدم مفاتيح المؤشر للتنقل في التواريخ",
      },
    };
  },
  computed: {
    isRTL() {
      return $themeConfig.layout.isRTL ? "rtl" : "ltr";
    },
  },
};
</script>

<style lang="scss">
.ngo-datepicker {
  footer {
    display: none;
  }
  .dropdown-menu {
    width: fit-content !important;
  }
  .b-form-date-calendar {
    width: fit-content !important;
  }
}
</style>
