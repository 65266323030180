 
<template>
  <validation-provider
    #default="{ errors }"
    :name="defaultName"
    mode="passive"
    :rules="rules"
    class="generate-password"
  >
    <!-- form input -->
    <b-form-group :label="label" :label-for="id">
      <b-input-group>
        <b-input-group-prepend
          is-text
          :class="{ 'cleave-pone': errors.length > 0 }"
          class="p-0 m-0 generate"
      
        >
          <b-button variant="white" class="p-0" @click="generatePassword()"> إنشاء </b-button>
        </b-input-group-prepend>
        <b-form-input
          :state="$attrs.state || errors.length > 0 ? false : null"
          :name="name"
          :id="id"
          v-bind="$attrs"
          v-on="$listeners"
          v-model="password"
        />

        <!-- button -->
        <b-input-group-prepend
          is-text
          :class="{ 'cleave-pone': errors.length > 0 }"
          class=" copyPassword p-0"
        >
          <b-button
            v-clipboard:copy="password"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
            variant="white"
            class="p-0"
          >
            <feather-icon icon="CopyIcon" size="22" color="#475569" />
          </b-button>
        </b-input-group-prepend>
      </b-input-group>
    </b-form-group>
    <small class="text-danger">{{ errors[0] }}</small>
  </validation-provider>
</template>
  
<script>
import { v4 as uuidv4 } from "uuid";
import { ValidationProvider } from "vee-validate";
import {
  BFormInput,
  BFormGroup,
  BButton,
  BInputGroup,
  BInputGroupPrepend,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import Ripple from "vue-ripple-directive";
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  min,
  digits,
  alphaDash,
  length,
} from "@validations";
export default {
  components: {
    BFormInput,
    BFormGroup,
    BButton,
    ToastificationContent,
    ValidationProvider,
    BInputGroup,
    BInputGroupPrepend,
  },
  props: {
    id: {
      type: String,
      default: () => "input",
    },
    name: {
      type: String,
    },
    rules: {
      type: String,
      default: () => "",
    },
    label: {
      type: String,
      default: () => "",
    },
    description: {
      type: String,
      default: () => "",
    },
    value:{
        type:String
    }
  },
  directives: {
    Ripple,
  },
  data() {
    return {
        password4:"",
      message: "Copy Me!",
      required,
      email,
      confirmed,
      url,
      between,
      alpha,
      integer,
      min,
      digits,
      alphaDash,
      length,
    };
  },
  methods: {
    generatePassword(){
        this.password =uuidv4().slice(0,8)
    },
    onCopy() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "تم نسخ كلمة السر",
          icon: "BellIcon",
        },
      });
    },
    onError() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Failed to copy texts!",
          icon: "BellIcon",
        },
      });
    },
  },
  computed: {
    defaultName() {
      return this.name || this.label;
    },
    password: {
      set(val) {
        this.$emit("input", val);
      },
      get() {
        return this.value;
      },
    },
  },

  
};
</script>

<style lang="scss">
.generate-password {
    .copyPassword.input-group-prepend .input-group-text {
    border-right: 1px solid #d8d6de;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}
</style>